  /**
   * Enum for all miscellaneous images
   */
  export enum MiscImagesIds {
    OMON_IMARALU = '1YatMaXlVrlsPsksp770dhui0AXilrVDj',
    OSAS_FERGUSON = '10ZQ6f4nYnJ-wCg7CxJLpzEqGPJ7qQRj1',
    DANIEL_OSEMEKE = '1kevxiwz1fB0z9-f9MO8XzTZZI_R88MJE',
    OUR_CULTURE = '1VYaBtUK7j1r_pcB49cchzgTx0lGt0XVG',
    CONTACT_US = '1n88j8eza271BwrTDC-Nq5UIFOhTZYMuD',
    ROLES_HEADER = '17DY88QbmOzM2volO0bTxlJMOvxbG2z_a'
  }